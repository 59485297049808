<!-- 顯示讀取中的 component -->
<template>
    <div>
        <b-alert variant="secondary" v-bind:style="{position: 'fixed', left:0, right:0, top: top + 'px', zIndex:1000}" show>⌛ &nbsp; {{ $t('message.waiting') }}</b-alert>
    </div>
</template>

<script>
    export default {
        name: "cmpFooter",
        data() {
            return {

            };
        },
        props: {
            top: { type: Number, default: 200 },    // 距離畫面上方多少 pixel
        },
    }

</script>